<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Company Level</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-app id="inspire" class="">
      <template v-if="load == true">
        <v-data-table
          :headers="headers"
          :items="companylevel_list"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              >
              </v-text-field>
              <v-spacer></v-spacer>
              <span style="width: 20px"></span>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="indigo darken-4"
                    dark
                    class="mb-2"
                    v-on="on"
                    @click="iseditable = !iseditable"
                    >New Company Level</v-btn
                  >
                </template>
                <v-card>
                  <v-form v-model="valid">
                    <v-card-title>
                      <v-row justify="space-between">
                        <span class="headline">{{ formTitle }}</span>
                        <v-btn icon dark @click="dialog = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                      <!-- <span class="headline">{{ formTitle }}</span> -->
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row> </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Company Level"
                              dense
                              persistent-hint
                              :rules="[(v) => !!v || 'required']"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <input
                              type="hidden"
                              v-model="editedItem.id"
                              name="id"
                            />
                            <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" sm="3" md="2">
                            <v-checkbox
                              v-model="editedItem.isactive"
                              color="success"
                              label="isActive?"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" outlined text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="primary darken-1"
                        dark
                        @click="save"
                        :disabled="!valid"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.isactive="{ item }">
            <v-switch
              v-model="item.isactive"
              @change="activeswitch(item)"
              :labels="{ checked: 'On', unchecked: 'Off' }"
            ></v-switch>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editItem(item)"
                  >edit</v-icon
                >
              </v-avatar>
              <v-btn text>
                <v-icon color="red" @click="deletefunc(item.id)">
                  mdi-delete</v-icon
                ></v-btn
              >
            </div>
          </template>
        </v-data-table>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    overlay: false,
    snackbar_msg: "",
    valid: false,
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    dialog: false,
    errorMsg: {
      name: false,
    },
    companylevel_list: null,

    search: "",
    headers: [
      {
        text: "Company Level",
        value: "name",
      },

      {
        text: "Status",
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",

      isactive: true,
    },
    defaultItem: {
      id: 0,
      name: "",

      isactive: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Company Level"
        : "Edit Company Level";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },
  mounted() {
    this.onLoad();
  },

  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.name = false;
    },
    onLoad() {
      this.overlay = true;
      axios

        .post("/AdminMaster/addCompanyLevel")

        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.overlay = false;
              this.companylevel_list = res.data.companylevel_list;

              this.load = true;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          // window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    editItem(item) {
      this.iseditable = false;
      this.editedIndex = this.companylevel_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        //  console.log("edit.." + this.editedItem);
        this.iseditable = false;
        axios
          .post("/AdminMaster/editCompanyLevel", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              Object.assign(
                this.companylevel_list[this.editedIndex],
                this.editedItem
              );

              this.showSnackbar(
                "#4caf50",
                " Company Level Updated Successfully..."
              ); // show snackbar on success
            } else if (res.data.status.code == "NA") {
              //alert(res.data.status.message)
              this.showSnackbar("#b71c1c", "CompanyLevel Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            //window.console.log(error);
          });
      } else {
        axios
          .post("/AdminMaster/saveCompanyLevel", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.companylevel_list.push(this.editedItem);
              this.showSnackbar(
                "#4caf50",
                "New CompanyLevel Added Successfully!!!"
              ); // show snackbar on success
            } else if (res.data.status.code == "NA") {
              //alert(res.data.status.message)
              this.showSnackbar("#b71c1c", "CompanyLevel Already Present!!!"); // show snackbar on error
            }
          })
          .catch((error) => {
            window.console.log(error);
          });
      }
      this.close();
    },
    deletefunc(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/AdminMaster/deleteCompanyLevel", data)
        .then((res) => {
          // console.log("a")
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      // console.log(item.id);

      axios
        .post("/AdminMaster/activeCompanyLevel", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            // console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
